import { useUnit } from 'effector-react'

import { ROUTES } from '@/constants'
import { ForCompanyOrIndividualProps } from '@screens/company/ui/types'
import { CollapsibleBlock } from '@widgets'
import Markdown from 'markdown-to-jsx'
import { useLocale, useTranslations } from 'next-intl'
import Link from 'next/link'
import { useEffect, useMemo, useState } from 'react'
import { SetRequired } from 'type-fest'

export const Summary = ({ for: $data, isFull: $isFull }: SetRequired<ForCompanyOrIndividualProps, 'isFull'>) => {
  const t = useTranslations()
  const locale = useLocale()

  const [company, isFull] = useUnit([$data, $isFull])

  const [output, setOutput] = useState(t('access_for_subscribers'))

  const cmp = useMemo(() => ({ ...company, financials: [] }), [company])

  useEffect(() => {
    ;(async () => {
      if (!isFull) {
        return
      }

      setOutput('')

      // @ts-ignore
      try {
        const reader = (await fetch(`/api/ai?name=${cmp.name}&lang=${locale}`)).body?.getReader()

        if (!reader) return

        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read()
          if (done) {
            break
          } else {
            setOutput((prev) => prev + new TextDecoder().decode(value))
          }
        }
      } catch (e) {
        setOutput(t('error'))
      }
    })()
  }, [t, isFull, cmp, cmp.name])

  return (
    <CollapsibleBlock title={'ИИ Отчет'} dataType={'ai-report'} id={'ai-report'}>
      {!isFull ? (
        <div className="col-span-full">
          <Link className="text-blue-400" href={ROUTES.PRICING} locale={locale} target="_blank">
            {output}
          </Link>
        </div>
      ) : (
        <Markdown className="col-span-full break-words whitespace-pre-wrap">{output}</Markdown>
      )}
    </CollapsibleBlock>
  )
}
