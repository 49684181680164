import { CheckIcon } from '@radix-ui/react-icons'
import { useUnit } from 'effector-react'
import { useLocale, useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { ROUTES } from '@/constants'
import { $userProfile } from '@/entities/user'

const plan_desc = {
  ru: [
    'Руководители и учредители',
    'Контактная информация',
    'Факторы риска',
    'Проверка по реестрам',
    'Судебные процессы',
    'История изменения руководителей, наименования, адреса',
    'Аффилированные лица и компании',
  ],
  en: [
    'Leaders and Founders',
    'Contact information',
    'Risk factors',
    'Registry check',
    'Litigation',
    'History of changes in leaders, names, addresses',
    'Affiliates and Companies',
  ],
}

export function BuyForm() {
  const t = useTranslations()
  const lang = useLocale()
  const [user] = useUnit([$userProfile])
  const router = useRouter()
  const [link, setLink] = useState<string>(ROUTES.PRICING)

  useEffect(() => {
    if (user) {
      setLink(ROUTES.PRICING)
    } else {
      setLink(`/auth?type=signin&redirect=${encodeURIComponent(router.asPath)}`)
    }
  }, [router.asPath, user])

  const payHandler = (isUser: boolean, have?: boolean) => {
    if (isUser) {
      router.push(ROUTES.PRICING)
    } else {
      router.push(`/auth?type=${have ? 'signin' : 'signup'}&redirect=${encodeURIComponent(router.asPath)}`)
    }
  }

  return (
    <>
      <div className="right-0 col-span-full row-span-2 h-full max-h-[380px] max-w-md lg:absolute">
        <div className="form flex h-full flex-col justify-between gap-5 rounded-md p-4 shadow-lg md:m-4">
          <div>
            <h2 className="font-stem text-lg font-medium lg:text-xl xl:text-2xl">{t('get_advanced_order')}</h2>
            <div className="mt-2 text-sm">
              {plan_desc[(lang as keyof typeof plan_desc) || 'ru'].map((item: string, index: number) => (
                <div key={index} className="flex items-start gap-2">
                  <div className="w-full max-w-[20px]">
                    <CheckIcon className="h-5 w-5 text-gray-500" />
                  </div>
                  {item}
                </div>
              ))}
            </div>
          </div>

          <div className="w-full">
            <button
              className="buy_order_btn tranisition mt-auto w-full rounded-md p-2 font-stem text-lg text-white duration-300 ease-in focus:outline-none lg:p-3.5 lg:text-xl"
              onClick={() => payHandler(!!user, false)}
            >
              {t('get_access')}
            </button>
          </div>
          <a
            href={link}
            rel="nofollow"
            onClick={() => payHandler(!!user, true)}
            className="block w-full text-center font-sans text-sm text-primary hover:text-flamingo"
          >
            {t(`i_have_account`)}
          </a>
        </div>
        <span className="mt-5 block text-xs leading-3 text-gray-500 md:m-4">{t('accept_text')}</span>
      </div>
      <div className="col-span-full row-span-2 h-12 lg:hidden" />
    </>
  )
}
