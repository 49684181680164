// @ts-nocheck
import { Jurisdiction } from '@/entities/jurisdiction'
import { $userProfile } from '@/entities/user'
import { Seo } from '@/features'
import { CompaniesList } from '@/ui'
import { AppliedFacets, Breadcrumbs, ExportSettings, Facets } from '@/widgets'
import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import Icon from '@/shared/ui/icon'
import { Alert, AlertTitle } from '@shared/ui/alert'
import { useDidUpdateEffect } from '../../features/useDidMountEffect'
import { queryToFilters } from './helpers'
import {
  $companiesFindPending,
  $filteredCompanies,
  $page,
  $searchLimitsByIdentifier,
  companiesFind,
  getSearchLimitsByIdentifier,
  resetFiltersAndPage,
} from './model'

export function SearchCompanyScreen({
  metaData,
}: {
  metaData: {
    description: string
    title: string
  }
}) {
  const router = useRouter()
  const searchParams = useSearchParams()
  const { state } = router.query
  const [userProfile, page, companyList, findCompanies, findCompaniesPending, searchLimits, getSearchLimits] = useUnit([
    $userProfile,
    $page,
    $filteredCompanies,
    companiesFind,
    $companiesFindPending,
    $searchLimitsByIdentifier,
    getSearchLimitsByIdentifier,
  ])
  const t = useTranslations()

  const listRef = useRef<HTMLDivElement | null>(null)
  const lastFetchTimeRef = useRef<number>(0)

  const isFiltersChanged = useMemo(() => {
    const filters = queryToFilters(searchParams)
    return JSON.stringify(filters)
  }, [searchParams])

  useDidUpdateEffect(() => {
    getSearchLimits()
  }, [findCompanies, isFiltersChanged, router.query?.value || '', router.query.state])

  useEffect(() => {
    getSearchLimits()
  }, [])

  useEffect(() => {
    resetFiltersAndPage()
    findCompanies({
      data: {
        filters: { ...queryToFilters(searchParams), jurisdiction: [router.query.state as string] },
      },
      query: {
        limit: 15,
        query: router.query?.value || '',
        page: 1,
      },
    })
  }, [searchParams, router.query.state])

  useDidUpdateEffect(() => {
    findCompanies({
      data: {
        filters: { ...queryToFilters(searchParams), jurisdiction: [router.query.state as string] },
      },
      query: {
        limit: 15,
        query: router.query?.value || '',
        page: page,
      },
    })

    getSearchLimits()
  }, [findCompanies, isFiltersChanged, router.query?.value || '', router.query.state])

  useDidUpdateEffect(() => {
    if (listRef.current) {
      listRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [router.query.value])

  const { ref, inView } = useInView({
    threshold: 1.0,
    triggerOnce: false,
  })

  const fetchMoreCompanies = useCallback(() => {
    const now = Date.now()
    const cooldown = 2000
    if (inView && !findCompaniesPending && now - lastFetchTimeRef.current > cooldown) {
      lastFetchTimeRef.current = now

      findCompanies({
        data: {
          filters: { ...queryToFilters(searchParams), jurisdiction: [router.query.state as string] },
        },
        query: {
          limit: 15,
          query: router.query?.value || '',
          page: page + 1,
        },
      })
    }
  }, [inView, findCompanies, findCompaniesPending, page, searchParams, router.query.state, router.query.value])

  useEffect(() => {
    fetchMoreCompanies()
  }, [fetchMoreCompanies])

  return (
    <>
      <Seo
        title={router.query?.value ? `Statsnet - ${router.query?.value}` : t('title_search')}
        description={(metaData && metaData.description) || t('description_search')}
      />
      <ExportSettings />
      <main className="relative mx-auto flex h-full w-full flex-auto items-start overflow-y-auto">
        <div className="absolute left-0 top-0 flex w-full max-w-full flex-auto">
          <div className="relative px-2 lg:pr-8" ref={listRef}>
            <div className="my-4">
              <Breadcrumbs />
            </div>
            {searchLimits !== null && searchLimits <= 20 && (
              <Alert className="bg-purple-100 text-gray-900 mb-2">
                <AlertTitle className="flex flex-row md:space-x-2 items-center">
                  <Icon className="block" name="search-x" size={16} />
                  <span>
                    {searchLimits <= 20
                      ? t.rich('identifier_search_limits', {
                          count: searchLimits,
                          counter: (chunk: string) => <span className="underline">{chunk}</span>,
                        })
                      : t('identifier_search_limits_exhausted')}
                  </span>
                </AlertTitle>
              </Alert>
            )}
            <AppliedFacets />

            <CompaniesList
              data={companyList}
              isLoading={findCompaniesPending}
              jurisdiction={state as Jurisdiction}
              withHighlighter
              hasNotFound
              search={router.query.value as string | undefined}
            />

            {!findCompaniesPending ? <div ref={ref} /> : null}
          </div>
          <Facets listRef={listRef} />
        </div>
      </main>
    </>
  )
}
