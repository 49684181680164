// @ts-nocheck
import { useUnit } from 'effector-react'
import { useLocale, useTranslations } from 'next-intl'
import dynamic from 'next/dynamic'
import { useCallback, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { SetRequired } from 'type-fest'

import { SafeView } from '@/features'
import { CollapsibleBlock } from '@/widgets'
import { $companyData } from 'src/screens/company/ui/finances/model'

import { generateHeaders } from '@/api/model'
import { countOfCategorySet } from '@/screens'
import axios from 'axios'
import { CompanyItemLoading } from '../../../../shared/ui/companies/company-item-loading'
import { CollapsibleDataSection } from '../collapsible-data-section'
import { ForCompanyOrIndividualProps } from '../types'
import { $viewMeta, visibilityChanged } from './model'

const VirtualizedTable = dynamic(() => import('@/ui').then((d) => d.VirtualizedTable), {
  ssr: false,
})

const USABLE_KEYS = [
  'stores',
  'jobs',
  'certs',
  'quasi_contracts',
  'debtors_amount',
  'debtors',
  'pos_terminals',
  'places',
  'trademarks',
  'bank_accounts',
  'marketplaces',
  'counterparties',
  'websites',
  'intellectual_properties',
  'gov_loans',
  'procurement',
]

const isDate = (date) => {
  return new Date(date) !== 'Invalid Date' && !isNaN(new Date(date))
}

const isDomain = (domain: string) =>
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(domain)

export function Activity({ for: $data, isFull: $isFull }: SetRequired<ForCompanyOrIndividualProps, 'isFull'>) {
  const t = useTranslations()
  const locale = useLocale()

  const [isFull, data, viewMeta, onVisible, companyData] = useUnit([
    $isFull,
    $data,
    $viewMeta,
    visibilityChanged,
    $companyData,
  ])
  const setCountOfCategory = useUnit(countOfCategorySet)
  const [regAllData, setAllRegData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const { ref } = useInView({
    threshold: 0,
    triggerOnce: true,
    onChange: (visible) =>
      onVisible({
        visible,
        id: data?.id,
        shouldBeLoaded: isFull,
      }),
  })

  const getTotalCount = (viewMeta) => {
    if (!viewMeta) return

    return Object.keys(viewMeta)
      .filter((key) => typeof viewMeta[key] === 'number' && key !== 'debtors_amount') // Виключаємо debtors_amount
      .reduce((sum, key) => sum + viewMeta[key], 0) // Підсумовуємо всі числові значення
  }

  const totalCount = getTotalCount(companyData)

  useEffect(() => {
    setCountOfCategory({
      category: 'activity_type',
      count: totalCount,
    })
  }, [totalCount, setCountOfCategory])

  const getData = useCallback(async (id: number) => {
    setIsLoading(true)
    const token = localStorage.getItem('token')
    const headers = {
      ...generateHeaders(),
      Authorization: token ? `Bearer ${token}` : undefined,
    }
    const response = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/api/v2/business/${id}/data/view/meta`, {
      method: 'GET',
      headers: headers,
    })

    setAllRegData(Object.values(response.data))
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (!isFull) return
    getData(data.id)
  }, [data?.id, isFull, getData])

  const transformValue = (value: string) => {
    if (!value) return '-'

    value = String(value)

    if (value.split(',').length > 1)
      return (
        <div className="flex flex-col">
          {value.split(',').map((elem, idx) => {
            if (isDomain(elem)) {
              return (
                <a key={`${elem}${idx}`} className="text-blue-400" href={elem} aria-label={elem}>
                  {elem}
                </a>
              )
            }

            return (
              <span key={`${elem}${idx}`} className="">
                {elem}
              </span>
            )
          })}
        </div>
      )
    else if (['true', 'false'].includes(value)) return value === 'true' ? t('yes') : t('no')
    else if (!isNaN(Number(value)) && value.length === 12) return value
    else if (!isNaN(Number(value))) return new Intl.NumberFormat(locale).format(Number(value))
    else if (isDomain(value))
      return (
        <a className="text-blue-400" href={value} aria-label={value}>
          {t('link')}
        </a>
      )
    else if (isDate(value)) return new Date(value).toLocaleDateString(locale)

    return value
  }

  return (
    <CollapsibleBlock
      title={
        <>
          {t('activity')}
          <span className="ml-2 text-sm font-normal">{totalCount}</span>
        </>
      }
      dataType="activity"
      id="activity"
      ref={ref}
      className="select-none"
    >
      <SafeView for={!isFull}>
        <div className="col-span-full">
          <VirtualizedTable mocked />
        </div>
      </SafeView>
      <SafeView for={isFull}>
        <SafeView for={!isLoading}>
          {regAllData.map((el) => {
            const table = el.data && el.data.length ? [...el.data] : []
            let keysTable = []

            if (table.length > 0) {
              const keys = Object.keys(table[0])

              keysTable = keys.map((key) => ({
                header: key ? t(key) : key,
                accessorKey: key,
                cell: ({ cell, row }) => {
                  return transformValue(cell.getValue())
                },
              }))
            }

            return (
              <SafeView for={el.key} otherwise="">
                {el.value && el.value !== t('not_found') ? (
                  <CollapsibleDataSection
                    isFull={isFull}
                    mocked={!isFull}
                    showCollapse={!!table.length}
                    title={el.key}
                    collapsed={table.length && <VirtualizedTable list={table} columns={keysTable} />}
                  >
                    {el.value}
                  </CollapsibleDataSection>
                ) : (
                  <div>{t('not_found')}</div>
                )}
              </SafeView>
            )
          })}
        </SafeView>
        <SafeView for={isLoading}>
          <div className="col-span-6">
            <CompanyItemLoading />
          </div>
        </SafeView>
      </SafeView>
    </CollapsibleBlock>
  )
}
