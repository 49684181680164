import { useTranslations } from 'next-intl'
import { ReactNode, useMemo, useState } from 'react'
import { useTable } from 'react-table'

import fmt from '@/lib/fmt'

export const Table = ({
  column,
  data,
  isFull,
  isDeductions = false,
  withFilter = false,
  withCustomHide = false,
  renderFilter,
  withBlur = false,
  company,
  ...rest
}: {
  column: any
  data: any
  style?: any
  isHistory?: boolean
  isFull?: boolean
  isDeductions?: boolean
  withFilter?: boolean
  withCustomHide?: boolean
  renderFilter?: () => ReactNode | null
  showFullTooltip?: boolean
  withBlur?: boolean
  company?: any
}) => {
  const columns = useMemo(() => column || [], [column])
  const [collapse, setCollapse] = useState(false)

  const checkFunction = () => {
    if (renderFilter) {
      try {
        renderFilter()

        return true
      } catch {
        return false
      }
    }

    return false
  }
  const rowsData = useMemo(
    () =>
      data
        ? data?.filter(Boolean)?.map((e: Record<any, any>) => ({
            ...e,
            ip_start_date: new Date(e.ip_start_date).getFullYear() !== 1 ? fmt.date(new Date(e.ip_start_date)) : '-',
            ['recovery_amount' || 'amount']: fmt.number(e['recovery_amount'] || e['amount'], 'kz'),
          }))
        : [],
    [data],
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: !isFull && !withBlur ? (rowsData && rowsData.length > 3 ? rowsData.slice(0, 3) : rowsData) : rowsData,
  })

  const t = useTranslations()

  return (
    <>
      {!withCustomHide && (
        <div className="grid w-full grid-cols-4">
          <div className="col-span-2">
            <button
              onClick={() => setCollapse(!collapse)}
              className="flex items-center rounded py-1 text-sm text-gray-400 outline-none duration-300 hover:text-primary focus:outline-none"
            >
              {collapse ? t(`show`) : t(`hide`)}
              <span className={`forTable text-gray-400 ${!collapse ? 'rotated' : ''}`} />
            </button>
          </div>
        </div>
      )}
      {withFilter && checkFunction() && renderFilter ? (
        <ul className="relative col-span-full mb-6 flex w-full space-x-2">{renderFilter()}</ul>
      ) : null}
      <div
        className="max-h-600 relative col-span-full min-w-full overflow-hidden overflow-x-auto overflow-y-auto"
        {...rest}
      >
        <table
          {...getTableProps()}
          className={`relative min-w-full divide-y divide-gray-200 ${(collapse && 'hidden') || ''}`}
        >
          <thead className={`bg-gray-50 `}>
            {headerGroups.map((headerGroup, idx) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={idx}>
                {headerGroup.headers.map((column, i) => (
                  <th
                    scope="col"
                    {...column.getHeaderProps()}
                    className="sticky top-0 border-0 bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    key={i}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className={`bg-white  ${isFull ? '' : withBlur ? 'blurred' : ''}`}>
            {rows.map((row, index) => {
              prepareRow(row)

              return (
                <tr {...row.getRowProps()} className="text-sm" key={row.id || index}>
                  {row.cells.map((cell, id) => {
                    const isDeductionsName = isDeductions && (cell.getCellProps().key as string).includes('name')
                    const setMinWidth =
                      cell.value && typeof cell.value !== 'string'
                        ? cell.value?.$$typeof
                          ? cell.value.props?.children?.props?.children?.length > 70
                          : cell.value[0]?.length > 70
                        : cell.value?.length > 70

                    return (
                      <td
                        {...cell.getCellProps()}
                        className="whitespace-nowrap px-6 py-4"
                        style={{
                          minWidth: isDeductions
                            ? isDeductionsName
                              ? '16rem'
                              : '8rem'
                            : setMinWidth
                              ? '16rem'
                              : 'min-content',
                          whiteSpace: isDeductions
                            ? isDeductionsName
                              ? 'normal'
                              : 'nowrap'
                            : typeof cell.value === 'string' && cell.value.includes('₸')
                              ? 'nowrap'
                              : 'normal',
                        }}
                        key={`cell.column.Header_${id}_${row.id}`}
                      >
                        {cell.column.Header === 'Взыскатель'
                          ? !data[index].recoverer_iin
                            ? `${data[index].recoverer_title}.
														 ${data[index].recoverer_bin}`
                            : !data[index].recoverer_bin
                              ? `${data[index].recoverer_iin} 
														 ${data[index].recoverer_name}
														 ${data[index].recoverer_second_name} 
														 ${data[index].recoverer_surname}`
                              : data[index].recoverer_iin
                          : (cell.value && cell.render('Cell')) || `0 ₸`}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}
