import { createStore, sample } from 'effector'
import _ from 'lodash'
import { combineEvents } from 'patronum'

import { loadContractsMetaFx, loadGovContractsFx, loadQuasiContractsFx } from '@/api'
import { createBindingForVisibility } from '@/features'
import { Nullable } from '@/T'
import { processMetaContracts } from 'src/screens/company/ui/gov-contracts/common'
import { ResponseType } from 'src/shared/api/model'

export const { $inView, $companyId, $isLoaded, visibilityChanged } = createBindingForVisibility()
export const $contractsMeta = createStore<Nullable<ResponseType<'/business/{id}/contracts/meta', 'get'>>>(null)
export const $govContracts = createStore<ResponseType<'/business/{id}/gov_contracts', 'get'>>({})
export const $quasiContracts = createStore<ResponseType<'/business/{id}/quasi_contracts', 'get'>>({})

sample({
  clock: $inView,
  source: { id: $companyId, isLoaded: $isLoaded },
  filter: ({ id, isLoaded }, inView) => !!id && !isLoaded && inView,
  fn: ({ id }) => ({
    path: {
      id: (id || 0) as string,
    },
  }),
  target: [loadContractsMetaFx, loadGovContractsFx, loadQuasiContractsFx],
})

sample({
  clock: loadContractsMetaFx.doneData,
  target: $contractsMeta,
})

sample({
  clock: loadQuasiContractsFx.doneData,
  fn: (data) => {
    return data
  },
  target: $quasiContracts,
})

sample({
  clock: loadGovContractsFx.doneData,
  target: $govContracts,
})

export const $applicationsYears = $contractsMeta.map((meta) => {
  if (meta) {
    return processMetaContracts('gov_applications_meta', meta)
  }
})

export const $govContractsYears = createStore({
  sumByContract: {},
  sumByYear: {},
  totalByContracts: 0,
  totalByYear: 0,
  totalCount: 0,
})

sample({
  clock: $contractsMeta,
  filter: Boolean,
  fn: (meta) => {
    const { amounts, totalAmount, years, totalYear } = processMetaContracts('gov_contracts_meta', meta)
    return {
      totalByYear: Math.round(Object.entries(amounts).length < 4 ? totalAmount : totalAmount / 10),
      sumByYear: amounts,
      sumByContract: years,
      totalByContracts: Math.round(Object.entries(years).length < 4 ? totalYear : totalYear / 10),
      totalCount: Math.round(totalYear),
    }
  },
  target: $govContractsYears,
})

export const $countTrade = $contractsMeta.map((meta) =>
  _.map(meta?.gov_contracts_meta, ({ method, count }) => ({ name: method, count })),
)

export const $quasiYears = $contractsMeta.map((meta) => {
  if (meta) {
    return processMetaContracts('quasi_contracts_meta', meta)
  }
})

sample({
  clock: combineEvents({
    events: [loadContractsMetaFx.done, loadGovContractsFx.done, loadQuasiContractsFx.done],
  }),
  fn: () => true,
  target: $isLoaded,
})
