import { createStore, sample } from 'effector'

import { loadDepartmentsFx } from '@/api'
import { ResponseType } from '@/api/model'
import { createBindingForVisibility } from '@/features'

export const { $inView, visibilityChanged, $companyId, $isLoaded, $shouldBeLoaded } = createBindingForVisibility()
export const $departments = createStore<ResponseType<'/business/{company_id}/department', 'get'>>({
  departments: [],
})

export const $isLoading = loadDepartmentsFx.pending

sample({
  clock: $inView,
  source: {
    companyId: $companyId,
    isLoaded: $isLoaded,
    shouldBeLoaded: $shouldBeLoaded,
  },
  filter: ({ companyId, isLoaded, shouldBeLoaded }, visible) => visible && !isLoaded && !!companyId && shouldBeLoaded,
  fn: ({ companyId }) => ({
    path: {
      company_id: companyId as number,
    },
    query: {},
  }),
  target: [loadDepartmentsFx],
})

sample({
  clock: loadDepartmentsFx.doneData,
  target: $departments,
})
