import { Field as FieldType, useField, useForm } from 'effector-forms'
import { useUnit } from 'effector-react'
import { useLocale, useTranslations } from 'next-intl'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FormEventHandler, useEffect, useMemo, useState } from 'react'

import { $loginErrorData, $loginUserProcessing } from '@/api'
import { Nullable } from '@/T'
import { Back, Button, CircularProgress, FloatInput } from '@/ui'
import { FloatInputProps } from 'src/shared/ui/form/float-input'

import clsx from 'clsx'
import { $$loginForm, $redirectURI, setRedirectURI } from './model'
import styles from './styles.module.css'

interface LoginErrors {
  email: Nullable<string>
  password: Nullable<string>
}

const Field = ({
  field,
  label,
  type,
  name,
  className,
  onChange: customOnChange,
}: { field: FieldType<any> } & FloatInputProps) => {
  const { onChange, value } = useField(field)
  return (
    <FloatInput
      onChange={(e) => {
        onChange(e.target.value)
        customOnChange?.(e)
      }}
      value={value}
      label={label}
      type={type}
      name={name}
      className={className}
    />
  )
}

export const LoginScreen = () => {
  const t = useTranslations()
  const locale = useLocale()

  const { submit, hasError, fields, errorText } = useForm($$loginForm)
  const [redirectURI, setToRedirectURI] = useUnit([$redirectURI, setRedirectURI])
  const [_, setValid] = useState(false)
  const router = useRouter()
  const [loginError, load] = useUnit([$loginErrorData, $loginUserProcessing])
  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault()
    submit()
  }

  useEffect(() => {
    if (router.query?.redirect_uri) {
      setToRedirectURI(decodeURI((router.query.redirect_uri as string) || redirectURI))
    }
  }, [setToRedirectURI, redirectURI, router.query?.redirect_uri])

  const errors = useMemo(
    () =>
      Object.entries(fields)
        .filter(([key, value]) => value.hasError())
        .map(([key, value]) => `${t(key)}: ${t(value.errorText({ required: 'required_field' })).toLowerCase()}`),
    [fields, t],
  )

  return (
    <main className="container flex h-screen flex-col items-center justify-center gap-y-3">
      <Link locale={locale} href="/" className="font-avenir text-2xl">
        Statsnet
      </Link>

      <p className="font-stem text-2xl">{t('signin')}</p>

      <div className="w-full max-w-sm">
        <form onSubmit={handleSubmit} className="mb-5 w-full">
          <div>
            <Field
              type="email"
              label="Email"
              name="email"
              className={clsx(styles.loginInput, { 'border-red-500': fields.email.errors.length })}
              autoComplete="email"
              field={$$loginForm.fields.email}
              onChange={(e) => e.target.value.length > 0 && setValid(true)}
            />

            <Field
              label={t('password')}
              type="password"
              name="password"
              className={clsx(styles.passwordInput, { 'border-red-500': fields.password.errors.length })}
              autoComplete="off"
              aria-autocomplete="none"
              field={$$loginForm.fields.password}
            />

            <Button
              type="submit"
              className={clsx(styles.button, 'w-full rounded bg-blue-500 hover:bg-blue-600')}
              disabled={load}
            >
              {load ? (
                <div className="mx-auto h-4 w-4">
                  <CircularProgress color="white" />
                </div>
              ) : (
                t(`signin`)
              )}
            </Button>

            <div className="mt-4">{/*<AuthWithGoogle />*/}</div>
          </div>

          <div className="mt-2 flex flex-col gap-x-1 text-red-500">
            {errors.map((error) => error && <p key={error}>{error}</p>)}
            {loginError && <p>{t('email_or_password_invalid')}</p>}
          </div>
        </form>
      </div>

      <div className="w-full max-w-sm text-left">
        <p className="self-start text-gray-500">{t('not_register_yet')}</p>
        <p className="text-blue-500 hover:text-blue-600">
          <Link
            locale={locale}
            prefetch={false}
            href={{
              pathname: '/auth/sign-up',
              query: router.query,
            }}
          >
            {t('register')}
          </Link>
        </p>
        <p className="text-blue-500 hover:text-blue-600">
          <Link locale={locale} href={{ pathname: '/auth/forget-password', query: router.query }}>
            {t('forget_password')}
          </Link>
        </p>
      </div>
      <Back />
    </main>
  )
}
