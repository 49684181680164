import moment from 'moment'
import { SetRequired } from 'type-fest'

import { Jurisdiction } from '@/entities/jurisdiction'
import { ResponseType } from 'src/shared/api/model'

const currencySign = {
  us: '$',
  kz: '₸',
  ua: '₴',
  ru: '₽',
  kg: 'сом',
  uz: 'UZS',
  by: 'Br',
  ge: '₾',
  az: '₼',
}

export type Currency = 'us' | 'kz' | 'ua' | 'ru' | 'kg' | 'uz' | 'by' | 'ge' | 'az'

interface BaseFormatInput {
  company:
    | Required<
        | ResponseType<'/business/{jurisdiction}/{id}/paid', 'get'>['company']
        | ResponseType<'/business/{jurisdiction}/{id}/view', 'get'>['company']
      >
    | SetRequired<ResponseType<'/business/jurisdiction/state', 'post'>, 'company'>['company'][0]
}

interface FormatAddressInput extends BaseFormatInput {
  jurisdiction: Jurisdiction
  jurisdictionTranslate: string
}

const mainJurisdictions = ['kz', 'uz', 'kg', 'ru']

export const belongsToMainJurisdictions = (jurisdiction: string) => {
  return mainJurisdictions.includes(jurisdiction?.toLowerCase())
}

function address({ company, jurisdiction, jurisdictionTranslate }: FormatAddressInput) {
  const firstAddress = company?.addresses?.[0]
  if (!firstAddress) return ''

  const { state, street, building, raw } = firstAddress

  if (jurisdiction === 'global') return raw

  if (state && street && building) return `${jurisdictionTranslate}, ${state}, ${street}, ${building}`

  if (raw) {
    const formattedAddress = raw.includes('[') ? raw.split('[').join().split(']').join().replace(/'/g, '') : raw
    return `${jurisdictionTranslate}, ${formattedAddress}`
  }

  return [state, street, building].filter(Boolean).join(', ')
}

function industryCode({ company }: BaseFormatInput) {
  if (!company) return null

  const { main_industry_code, industry_codes } = company

  if (main_industry_code?.is_main) {
    return main_industry_code.description
  }

  if (industry_codes?.length) {
    const mainIndustryDescriptions = industry_codes
      .filter(({ is_main }) => is_main)
      .map(({ description }) => description)

    return mainIndustryDescriptions.length ? mainIndustryDescriptions.join(', ') : industry_codes[0]?.description
  }

  return null
}

function headCount({ company }: BaseFormatInput) {
  if (company && 'headcount' in company) {
    const { headcount } = company
    let formattedHeadcount = '· 1 - 5'

    const isArray = Array.isArray(headcount)

    if (isArray) {
      formattedHeadcount = ` · ${headcount[0]?.count === '<= 5' ? '1 - 5' : headcount[0]?.count || '1 - 5'}`
    } else {
      const isLessThanOrEqualTo5 = headcount === '<= 5' || headcount === 1

      if (isLessThanOrEqualTo5) {
        formattedHeadcount = ' · 1 - 5'
      }
    }

    return formattedHeadcount
  }

  return '· 1 - 5'
}

function toProperCase(s: string): string {
  return s
    ?.split(' ')
    ?.map((w) =>
      w && w[0] && w[1] && w.length > 2 && w === 'and'
        ? w.toLowerCase()
        : w[0] === `“` || w[0] === '"' || w[0] === "'"
          ? w[0] && w[0] + (w[1]?.toUpperCase() || '') + w.substring(2).toLowerCase()
          : w[0] && w[0].toUpperCase() + w.substring(1).toLowerCase(),
    )
    ?.join(' ')
}

function toTitleCase([first, ...rest]: any): string {
  return (first && first.toUpperCase() + rest.join('').toLowerCase()) || ''
}

function chooseCurrency(code: Currency) {
  return ` ${currencySign[code]}`
}
function number(s: string | number | undefined, jurisdiction: Currency, currency = true) {
  return `${rawNumber(s)} ${currency ? chooseCurrency(jurisdiction) : ''}` || ''
}

function rawNumber(s: string | number | undefined) {
  return (s && `${s.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}`) || ''
}

function date(d: Date) {
  if (d.getFullYear() === 1) return '-'
  let dd = d.getDate().toString()
  let mm = (d.getMonth() + 1).toString()
  const yyyy = d.getFullYear()

  if (parseInt(dd) < 10) {
    dd = '0' + dd
  }

  if (parseInt(mm) < 10) {
    mm = '0' + mm
  }
  return `${dd}.${mm}.${yyyy}`
}

function textAge(age: number): string {
  if (age === 0) return ''

  let count: string | number = age % 100

  const txt =
    count >= 5 && count <= 20 ? 'лет' : (count = count % 10 === 1 ? 'год' : count >= 2 && count <= 4 ? 'года' : 'лет')
  return `${age} ${txt}`
}

function textMonth(month: number): string {
  const postfixes = ['месяц', 'месяца', 'месяцев']
  return month === 1 || month === 0
    ? `${month || 1} ${postfixes[0]}`
    : month >= 2 && month <= 4
      ? `${month} ${postfixes[1]}`
      : `${month} ${postfixes[2]}`
}

function toToday(d: string, withDays = false): string {
  const past = moment(d)
  const now = moment()

  const years = now.diff(past, 'years')
  const months = now.diff(past, 'months') % 12
  const days = now.diff(past, 'days') % 365

  let timepass = `${textAge(years)} ${textMonth(months)} `

  if (withDays) {
    timepass +=
      days === 0 ? '' : days === 1 ? '1 день назад' : days < 5 && days > 1 ? `${days} дня назад` : `${days} дней назад`
  }

  return timepass.trim()
}

function abbreviateNumber(n: number) {
  const sizes = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
  ]

  if (n < 1) return n.toString()

  const i = Math.floor(Math.log(n) / Math.log(1000))

  return (n / sizes[i].value).toFixed(2) + sizes[i].symbol
}

function getProperAreaFormat(
  searchedArea: string,
  jurisdiction: string,
  areaData?: Record<string, Array<{ old: string; new: string }>>,
) {
  if (!belongsToMainJurisdictions(jurisdiction) || !areaData || !areaData[jurisdiction]) {
    return toProperCase(searchedArea)
  }

  const result = areaData[jurisdiction].find((x) => x.old === searchedArea)

  if (result) return result.new
  return toProperCase(searchedArea)
}

function addressCropper(address?: Record<string, string>): string {
  if (!address) {
    return ''
  }

  const { state, street, area, raw, building, apt } = address

  const values = [state, area, street, building, apt].filter((value) => value)

  if (values.length / Object.keys(address).length < 0.6) {
    return raw
  }

  return values.join(', ')
}

const fmt = {
  address,
  industryCode,
  headCount,
  toProperCase,
  toTitleCase,
  chooseCurrency,
  rawNumber,
  number,
  date,
  toToday,
  textAge,
  textMonth,
  abbreviateNumber,
  getProperAreaFormat,
  addressCropper,
}

export default fmt
