import { useStoreMap, useUnit } from 'effector-react'
import { filter, union, uniqBy } from 'lodash'
import { useTranslations } from 'next-intl'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { SetRequired } from 'type-fest'

import { $userProfile } from '@/entities/user'
import { SafeView } from '@/features'
import { $companyContacts, countOfCategorySet } from '@/screens'
import { Placeholder } from '@/ui'
import { CollapsibleBlock } from '@/widgets'
import Icon from '@shared/ui/icon'

import { DataSection } from 'src/screens/company/ui/data-section'

import {
  $contactsReactions,
  $socialNetworksContacts,
  getContactsReactions,
  postContactsReaction,
  setContactsReactionsIDs,
  visibilityChanged,
} from './model'

import ChangeInformationCompanyModal from '../company-feedback-modal/change-information-company-modal'
import { ForCompanyOrIndividualProps } from '../types'

const ReactionBlock = ({ contact_id }: { contact_id: number }) => {
  const [user, sendContactsReaction, contactsReactionsList] = useUnit([
    $userProfile,
    postContactsReaction,
    $contactsReactions,
  ])

  const reaction = contactsReactionsList?.find((item: any) => item.contact_id === contact_id)

  const react = async (is_like: boolean) => {
    sendContactsReaction({ contact_id, is_like })
  }

  const likeReaction = reaction && reaction.is_like ? reaction : null
  const dislikeReaction = reaction && !reaction.is_like ? reaction : null

  return (
    <div className="flex items-center space-x-2">
      <button
        disabled={!user}
        onClick={() => user && react(true)}
        type="button"
        className="flex items-center hover:bg-gray-100 p-1 rounded-md"
      >
        <Icon name="thumbs-up" className="h-4 w-4 text-gray-500 " />
        {likeReaction && <span className="ml-1">{likeReaction.count}</span>}
      </button>
      <button
        disabled={!user}
        onClick={() => user && react(false)}
        type="button"
        className="flex items-center hover:bg-gray-100 p-1 rounded-md"
      >
        <Icon name="thumbs-down" className="h-4 w-4 text-gray-500 " />
        {dislikeReaction && <span className="ml-1">{dislikeReaction.count}</span>}
      </button>
    </div>
  )
}

export function Contacts({
  for: $data,
  isFull: $isFull,
  person = false,
  meta: $meta,
}: SetRequired<ForCompanyOrIndividualProps, 'isFull' | 'meta'>) {
  const t = useTranslations()

  const [
    data,
    isFull,
    socialNetworksContacts,
    meta,
    setCountOfCategory,
    setContactsReactionsIDsList,
    getContactsReactionsList,
    onVisible,
  ] = useUnit([
    $data,
    $isFull,
    $socialNetworksContacts,
    $meta,
    countOfCategorySet,
    setContactsReactionsIDs,
    getContactsReactions,
    visibilityChanged,
  ])

  const [user] = useUnit([$userProfile])

  const { ref } = useInView({
    onChange: (visible) =>
      onVisible({
        visible,
        id: data?.id,
        shouldBeLoaded: isFull,
      }),
  })

  const correctContacts = useStoreMap({
    store: $data,
    keys: [person, data?.id],
    fn: (data, [person]) => {
      if (person) {
        return data?.contacts
      }
      const correctContacts: Record<string, any>[] = []

      if (data?.contacts?.length) {
        data.contacts.forEach((contact: Record<string, any>) => correctContacts.push(contact))
      }
      if (data?.shareholders?.length) {
        //@ts-ignore
        data.shareholders.forEach(({ contacts }) =>
          contacts?.forEach((contact: Record<string, any>) => correctContacts.push(contact)),
        )
      }
      if (data?.officers?.length) {
        //@ts-ignore
        data.officers.forEach(({ contacts }) =>
          contacts?.forEach((contact: Record<string, any>) => correctContacts.push(contact)),
        )
      }

      return uniqBy(correctContacts, 'value')
    },
  })

  const test = useUnit($socialNetworksContacts)

  const [phoneContacts, mailContacts, websiteContacts, socialNetworkContacts, totalLength] = useStoreMap({
    store: $companyContacts,
    keys: [correctContacts, data?.id],
    fn: (data) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const castedData = data!
      const fullContacts = union(castedData.phone, castedData.email, castedData.website, castedData.social_networks)
      let totalLength = 0

      for (const key in castedData) {
        if (Array.isArray(castedData[key as keyof typeof data])) {
          totalLength += (castedData[key as keyof typeof data] as []).length
        }
      }
      return [
        //@ts-ignore
        filter(fullContacts, ({ type, phone }) => type === 'phone' || phone?.length > 0),
        //@ts-ignore
        filter(fullContacts, ({ type, email }) => type === 'email' || email?.length > 0),
        //@ts-ignore
        filter(fullContacts, ({ type, website }) => type === 'website' || website?.length > 0),
        castedData.social_networks,
        totalLength,
      ]
    },
  })

  let socialNetworkContactsdev = socialNetworkContacts

  //@ts-ignore
  socialNetworkContactsdev = test.length ? test : []

  useEffect(() => {
    if (isFull) {
      setCountOfCategory({
        category: 'contacts',
        count: (totalLength || 0) + (socialNetworksContacts?.length || 0),
      })
    } else {
      setCountOfCategory({
        category: 'contacts',
        count: meta?.contacts || meta?.meta_contacts,
      })
    }
  }, [
    correctContacts?.length,
    meta?.contacts,
    meta?.meta_contacts,
    isFull,
    socialNetworksContacts?.length,
    setCountOfCategory,
    totalLength,
  ])

  useEffect(() => {
    if (!isFull) return

    const nonSocialNetworkContactsList = [...phoneContacts, ...mailContacts, ...websiteContacts]

    setContactsReactionsIDsList(
      //@ts-ignore
      nonSocialNetworkContactsList.filter((contact) => contact && contact.id).map((contact) => contact.id),
    )
    getContactsReactionsList()
  }, [
    getContactsReactionsList,
    isFull,
    mailContacts,
    phoneContacts,
    setContactsReactionsIDsList,
    socialNetworkContactsdev,
    websiteContacts,
  ])

  return (
    <CollapsibleBlock
      dataType="contacts"
      id="contacts"
      className="select-none"
      ref={ref}
      title={
        <>
          {t('contacts')}
          <span className="ml-2 select-none text-sm font-normal">
            {isFull
              ? (totalLength || 0) + (socialNetworksContacts?.length || 0)
              : meta?.contacts || meta?.meta_contacts}
          </span>
        </>
      }
    >
      <DataSection title={t('phone')}>
        <SafeView for={isFull} otherwise={<Placeholder withGeneration isPhone />}>
          <SafeView for={phoneContacts} withProps otherwise={t('not_found')}>
            {(contacts) =>
              contacts.map((contact, idx) => (
                <span
                  key={
                    //@ts-ignore
                    `${idx}-${contact?.phone}-${contact?.value}`
                  }
                  className="flex flex-col md:flex-row md:space-x-2"
                >
                  <span className="cursor-pointer">
                    {
                      //@ts-ignore
                      contact.value || contact?.phone
                    }
                  </span>

                  {
                    //@ts-ignore
                    contact.source ? <span className="text-gray-500">{contact.source}</span> : null
                  }

                  <SafeView
                    for={
                      //@ts-ignore
                      contact.id
                    }
                  >
                    <ReactionBlock
                      contact_id={
                        //@ts-ignore
                        contact.id
                      }
                    />
                  </SafeView>
                </span>
              ))
            }
          </SafeView>
        </SafeView>
      </DataSection>
      <DataSection title={t('email')}>
        <SafeView for={isFull} otherwise={<Placeholder withGeneration isMail />}>
          <SafeView for={mailContacts} withProps otherwise={t('not_found')}>
            {(contacts) =>
              contacts.map((contact, idx) => (
                <span
                  data-id={
                    //@ts-ignore
                    contact.id
                  }
                  key={`${idx}-${
                    //@ts-ignore
                    contact?.email
                  }`}
                  className="flex flex-col md:flex-row md:space-x-2"
                >
                  <span className="cursor-pointer">
                    {
                      //@ts-ignore
                      contact.value || contact?.email
                    }
                  </span>

                  {
                    //@ts-ignore
                    contact.source ? <span className="text-gray-500">{contact.source}</span> : null
                  }

                  <SafeView
                    for={
                      //@ts-ignore
                      contact.id
                    }
                  >
                    <ReactionBlock
                      contact_id={
                        //@ts-ignore
                        contact.id
                      }
                    />
                  </SafeView>
                </span>
              ))
            }
          </SafeView>
        </SafeView>
      </DataSection>
      <DataSection title={t('website')} colored={websiteContacts?.length > 0}>
        <SafeView for={isFull} otherwise={<Placeholder withGeneration isWebSite />}>
          <SafeView for={websiteContacts} withProps otherwise={t('not_found')}>
            {(contacts) =>
              contacts.map((contact, idx) => (
                <span
                  data-id={
                    //@ts-ignore
                    contact.id
                  }
                  key={`${idx}-${
                    //@ts-ignore
                    contact?.website
                  }`}
                  className="flex flex-col md:flex-row md:space-x-2"
                >
                  <a
                    href={
                      //@ts-ignore
                      `https://${contact.value || contact.website}`
                    }
                    target="_blank"
                    rel="nofollow"
                    className="cursor-pointer"
                  >
                    {
                      //@ts-ignore
                      contact.value || contact?.website
                    }
                  </a>

                  {
                    //@ts-ignore
                    contact.source ? <span className="text-gray-500">{contact.source}</span> : null
                  }

                  <SafeView
                    for={
                      //@ts-ignore
                      contact.id
                    }
                  >
                    <ReactionBlock
                      contact_id={
                        //@ts-ignore
                        contact.id
                      }
                    />
                  </SafeView>
                </span>
              ))
            }
          </SafeView>
        </SafeView>
      </DataSection>
      <DataSection
        title={t('social_networks')}
        colored={socialNetworkContactsdev && socialNetworkContactsdev?.length > 0}
      >
        <SafeView for={isFull} otherwise={<Placeholder withGeneration />}>
          <SafeView for={socialNetworkContactsdev} withProps otherwise={t('not_found')}>
            {(contacts) =>
              //@ts-ignore
              contacts.map((contact: Record<string, any>, idx) => (
                <span
                  data-id={contact.id}
                  key={`${idx}-${contact?.url}`}
                  className="flex flex-col md:flex-row md:space-x-2"
                >
                  <a href={contact.url} target="_blank" rel="nofollow" className="cursor-pointer">
                    {contact?.account_name}&nbsp;{contact?.matched_contact?.[0] ? ' ' : ''}
                    {contact?.matched_contact?.[0] ? (
                      <span className="text-gray-500">{contact?.matched_contact[0]}</span>
                    ) : null}
                  </a>

                  <SafeView for={contact.id}>
                    <ReactionBlock contact_id={contact.id} />
                  </SafeView>
                </span>
              ))
            }
          </SafeView>
        </SafeView>
      </DataSection>
      <div className="col-span-full flex flex-col break-normal">
        {data?.structure === 'se' ? (
          <a
            href="https://goszakup.gov.kz"
            target="_blank"
            rel="nofollow"
            className="сol-span-full mt-4 text-xs text-gray-500 hover:text-gray-500"
          >
            {t('open_source', {
              name: 'goszakup.gov.kz',
            })}
          </a>
        ) : null}
      </div>

      <ChangeInformationCompanyModal for={$data} email={user?.email || ''} phone={user?.phone || ''}>
        <div className="col-span-full">
          <button className="flex w-fit flex-col break-normal text-xs text-gray-400">{t('edit_contacts')}</button>
        </div>
      </ChangeInformationCompanyModal>
    </CollapsibleBlock>
  )
}
