import type { ReactNode } from 'react'

type RenderChildrenProps<E> = {
  for: E
  withProps: true
  otherwise?: ReactNode | null
  children: (data: NonNullable<E>) => ReactNode
}

type PrimaryChildren<E> = {
  for: E
  withProps?: false
  otherwise?: ReactNode | null
  children: ReactNode
}

type Props<E> = RenderChildrenProps<E> | PrimaryChildren<E>

export function SafeView<E>({ for: data, children, otherwise = null, withProps = false }: Props<E>) {
  if (!data) return <>{otherwise}</>

  if ((Array.isArray(data) || typeof data === 'string') && data.length === 0) return <>{otherwise}</>

  if (typeof data === 'boolean') return data ? <>{children}</> : <>{otherwise}</>

  if (withProps && typeof children === 'function' && children) return <>{children(data)}</>

  return <>{children}</>
}
