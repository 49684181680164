import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'

import { SafeView, Seo, usePlan } from '@/features'
import { FullLabel } from '@/ui'
import { PlanModalWindow, ScrollToTop } from '@/widgets'
import { Alternatives } from 'src/screens/company/ui/alternatives'

import {
  $company,
  $companyAlternatives,
  $companyMeta,
  $companyPageSeo,
  $companyRelations,
  $companyRisks,
  $isFullReport,
} from './model'

import { $userProfile } from '@/entities/user'
import { Summary } from '@screens/company/ui/ai/summary'
import ErrorNotification from '../../widgets/errorNotification'
import { profileFieldUpdated } from '../user-area/profile/model'
import {
  Activity,
  ActivityType,
  Address,
  BriefInformation,
  CompanyCrumbs,
  CompanyFeedbackModal,
  Connections,
  Contacts,
  CourtCases,
  Employees,
  EquityParticipationInCompanies,
  Finances,
  GovContracts,
  MainInformation,
  Navigation,
  RegistrationHistory,
  Risks,
  SeoDescription,
  StructuredData,
} from './ui'
import { BrowserActivity } from './ui/browser-activity/browser-activity'
import { Departments } from './ui/departments'

export function CompanyScreen() {
  const t = useTranslations()

  const [company, seo, isFull] = useUnit([$company, $companyPageSeo, $isFullReport])
  const [user, updateProfile] = useUnit([$userProfile, profileFieldUpdated])

  const { planModalWindow } = usePlan()
  const showWarning = () => {
    if (!user) return null

    const dateTimeVariable = new Date(user.end_date)
    const now = new Date()

    if (!isFull) {
      if (!user?.plan_id) {
        return <ErrorNotification message={'У вас нет платной подписки'} color={'white'} />
      } else if (now > dateTimeVariable) {
        return <ErrorNotification message={'Ваша подписка истекла'} color={'white'} />
      } else if (!user?.reports) {
        return <ErrorNotification message={'У вас закончились проверки'} color={'white'} />
      }
    }
  }

  return (
    <>
      {showWarning()}
      {planModalWindow && <PlanModalWindow redirect={`/companies/${company?.jurisdiction}/${company?.id}`} />}
      <main className="container mx-auto">
        <Seo
          title={seo?.title}
          description={seo?.description}
          keywords={seo?.keywords}
          // @ts-ignore
          structureData={seo?.microdata}
          structure={company?.structure}
          canonical={true}
        />
        <SafeView for={isFull}>
          <FullLabel />
        </SafeView>
        <CompanyCrumbs />
        <BriefInformation for={$company} isFull={$isFullReport} />
        {/* @ts-ignore*/}
        <Navigation risks={$companyRisks} for={$company} meta={$companyMeta} />
        <MainInformation for={$company} isFull={$isFullReport} />
        <SafeView for={company}>
          <Summary for={$company} isFull={$isFullReport} />
        </SafeView>
        <Contacts for={$company} isFull={$isFullReport} meta={$companyMeta} />
        <Finances for={$company} isFull={$isFullReport} />
        <ActivityType for={$company} />

        <SafeView for={company?.jurisdiction === 'kz'}>
          <GovContracts for={$company} isFull={$isFullReport} />
          <RegistrationHistory for={$company} isFull={$isFullReport} />
        </SafeView>

        <SafeView for={company?.jurisdiction !== 'ru'}>
          <EquityParticipationInCompanies for={$company} isFull={$isFullReport} meta={$companyMeta} />

          <SafeView for={company?.jurisdiction === 'kz'}>
            <Activity for={$company} isFull={$isFullReport} meta={$companyMeta} />
          </SafeView>
        </SafeView>

        <Connections for={$company} isFull={$isFullReport} relations={$companyRelations} />

        <SafeView for={company?.jurisdiction !== 'ru'}>
          <CourtCases for={$company} isFull={$isFullReport} />
        </SafeView>

        <SafeView for={company?.jurisdiction === 'kz'}>
          <Employees for={$company} isFull={$isFullReport} meta={$companyMeta} />
          <BrowserActivity for={$company} isFull={$isFullReport} />
        </SafeView>

        <Departments for={$company} isFull={$isFullReport} meta={$companyMeta} />

        <SafeView for={company?.jurisdiction === 'kz'}>
          <Risks for={$company} risks={$companyRisks} isFull={$isFullReport} person={false} />
        </SafeView>

        <Address for={$company} />

        <SeoDescription for={$company} />

        <Alternatives alternatives={$companyAlternatives} isFull={$isFullReport} data={$company} />
        <div className="w-full">
          <CompanyFeedbackModal for={$company} />
        </div>

        <ScrollToTop />
      </main>
      <StructuredData />
    </>
  )
}
