import clsx from 'clsx'
import { useUnit } from 'effector-react'
import { useLocale, useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import React, { FormEventHandler, useState } from 'react'

import { $isSnippetsLoading } from '@/api'
import { Jurisdictions } from '@/entities/jurisdiction'
import { $userProfile } from '@/entities/user'
import { SafeView } from '@/features'
import { AsyncInput } from '@/ui'

import { ROUTES } from '@/constants'
import Icon from '@shared/ui/icon'
import { Tooltip, TooltipContent, TooltipTrigger } from '@shared/ui/tooltip'
import Link from 'next/link'
import {
  $jurisdiction,
  $query,
  $suggestedCompanies,
  companiesSuggested,
  jurisdictionChanged,
  queryChanged,
} from './model'
import { SuggestedList } from './ui/suggested-list'

interface SearchSnippetsProps {
  placeholders?: string | string[]
  readonly reduced?: boolean
}

export const SearchSnippets = ({ reduced = false, placeholders }: SearchSnippetsProps) => {
  const t = useTranslations()
  const locale = useLocale()

  const router = useRouter()
  const [showAlert, setShowAlert] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const user = useUnit($userProfile)

  const onFocus = () => {
    setIsFocused(true)
  }

  const onBlur = (e: React.FocusEvent<HTMLFormElement, Element>) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsFocused(false)
    }
  }
  const [loading, handleSuggestCompany, suggestedCompanies, query2, , jurisdiction, onJurisdictionChange] = useUnit([
    $isSnippetsLoading,
    companiesSuggested,
    $suggestedCompanies,
    $query,
    queryChanged,
    $jurisdiction,
    jurisdictionChanged,
  ])

  const [query, setQuery] = useState(query2)

  //@ts-ignore
  const handleInputChange = (e) => {
    const inputValue = e.target.value
    setQuery(inputValue) // Оновлення введеного тексту

    // Якщо введене число і користувач має певний план
    if (inputValue && !isNaN(Number(inputValue))) {
      if (!user?.plan_id || user?.plan_id === 1 || user?.plan_id === 2) {
        setShowAlert(true)
        setTimeout(() => setShowAlert(false), 5000) // Показати плашку протягом 5 секунд
        return
      }
    }
  }

  // Обробник натискання кнопки "Пошук"
  const submitHandler: FormEventHandler = (e) => {
    e.preventDefault()
    if (!showAlert) {
      router.push({
        pathname: '/search/[state]/[value]',
        query: { state: jurisdiction, value: query },
      })
    }
  }

  return (
    <form
      onFocus={onFocus}
      onBlur={onBlur}
      className={clsx({
        'input-field  relative mb-2 mt-10 flex flex-col bg-transparent sm:flex-row': !reduced,
        'max-w-50 relative bg-transparent text-xs md:w-80 md:max-w-[285px]': reduced,
      })}
      onSubmit={(e) => submitHandler(e)}
      itemProp="potentialAction"
      itemScope
      itemType="https://schema.org/SearchAction"
    >
      <div className={clsx({ 'relative my-4 w-full rounded-l-md sm:w-3/4 md:my-0': !reduced })}>
        <meta itemProp="target" content="https://statsnet.co/search/kz/{search_term_string}" />
        <div className="input-wrapper" style={{ position: 'relative' }}>
          <AsyncInput
            itemProp="query-input"
            type="text"
            name="search_term_string"
            autoComplete="off"
            placeholder={placeholders || [t('main_search_placeholder')]}
            loading={loading}
            asyncEvent={(search) =>
              handleSuggestCompany({
                filters: {
                  jurisdiction: ['kz'] as [Jurisdictions],
                },
                query: search,
              })
            }
            data={suggestedCompanies.company}
            value={query}
            onChange={handleInputChange}
            popupClassName={clsx('h-fit', {
              'text-[14px]': reduced,
            })}
            inputClassName={clsx({
              'h-12 p-4 rounded-md sm:rounded-r-none border md:border-r-0 transition duration-500 ease-in-out focus:outline-none focus:ring focus:border-blue-300':
                !reduced,
              'outline-none focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50 duration-300 text-sm justify-end md:justify-start h-full w-full px-2 text-black py-2 border rounded':
                reduced,
            })}
            showResults={isFocused}
          >
            {/* @ts-ignore*/}
            {(company) => <SuggestedList data={company} search={query} />}
          </AsyncInput>
        </div>
      </div>

      <SafeView for={!reduced} otherwise={null}>
        <div className="my-4 w-full sm:w-1/4 md:my-0 ">
          <select
            className="doubled-arrow h-12 w-full rounded sm:rounded-bl-none sm:rounded-tl-none border bg-white pl-2 transition duration-500 ease-in-out focus:outline-none sm:rounded-none md:border-l-0"
            onChange={(e) => onJurisdictionChange(e.target.value as Jurisdictions)}
            value={jurisdiction}
          >
            {['kz', 'kg', 'uz'].map((jurisdiction) => (
              <option key={jurisdiction} value={jurisdiction}>
                {t(`jurisdictions_for_mainselect.${jurisdiction}`)}
              </option>
            ))}
          </select>
        </div>

        <div className="my-4 flex h-12 w-full sm:w-2/4 md:my-0">
          <Link
            locale={locale}
            href={`${ROUTES.SEARCH}/${jurisdiction}/${query}`}
            className="flex w-full items-center justify-center rounded rounded-r-none bg-blue-500 text-white transition hover:bg-blue-600 sm:rounded-none"
            rel="nofollow"
          >
            {t('search')}
          </Link>

          <Tooltip>
            <TooltipTrigger>
              <Link
                locale={locale}
                href={`${ROUTES.SEARCH}/${jurisdiction}/${query}`}
                className="flex h-full w-full items-center justify-center rounded rounded-l-none bg-blue-500 px-2 font-medium text-white transition hover:bg-blue-600"
                rel="nofollow"
              >
                <Icon name="filter" size={32} />
              </Link>
            </TooltipTrigger>
            <TooltipContent>
              <span>{t('search_detailed')}</span>
            </TooltipContent>
          </Tooltip>
        </div>

        {showAlert && (
          <div className="alert">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{ marginRight: '10px' }}
            >
              <path fill="#FFFF67" d="M9.333 14.667h5.333V20H9.333z"></path>
              <path fill="#A665F5" d="M4 4h5.333v5.333H4z"></path>
              <path fill="#000" d="M14.667 9.333H20v5.333h-5.333z"></path>
              <path fill="#42FEFF" d="M4 9.333h5.333v5.333H4z"></path>
              <path fill="#51DA4C" d="M9.334 9.333h5.333v5.333H9.334z"></path>
              <path fill="#AC7558" d="M4 14.667h5.333V20H4z"></path>
              <path fill="#FF6E3D" d="M14.667 4H20v5.333h-5.333z"></path>
              <path fill="#EB55F7" d="M14.667 14.667H20V20h-5.333z"></path>
              <path fill="#3C46FF" d="M9.334 4h5.333v5.333H9.334z"></path>
            </svg>
            У вас бесплатный тариф
          </div>
        )}
      </SafeView>

      <SafeView for={reduced} otherwise={null}>
        <input type="submit" hidden />
      </SafeView>
    </form>
  )
}
