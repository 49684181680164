import { EffectorNext } from '@effector/next'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { deleteCookie, getCookie } from 'cookies-next'
import { allSettled, fork, serialize } from 'effector'
import { useUnit } from 'effector-react'

import { NextPage } from 'next'
import { IntlProvider } from 'next-intl'
import App, { AppContext, AppProps } from 'next/app'
import { Router } from 'next/router'
import NProgress from 'nprogress'

import { ReactElement, ReactNode, useEffect, useState } from 'react'

import { $userProfile, userLoggedOut } from '@/entities/user'

import { PlanModalWindowContext, Seo } from '@/features'
import { $refreshToken, $userToken, appStarted, clientCookiesSet } from '@/shared-events'
import { CookieFields } from '@/T'
import { Layout } from '@/ui'

import { TooltipProvider } from '@shared/ui/tooltip'
import 'nprogress/nprogress.css'
import '../../styles/globals.css'

NProgress.configure({
  minimum: 0.1,
  easing: 'ease',
  speed: 800,
  showSpinner: false,
})

Router.events.on('routeChangeStart', () => {
  NProgress.start()
})
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

type PageProps = {
  // eslint-disable-next-line no-undef
  messages: IntlMessages
  now: number
  values: any
}

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps &
  Omit<AppProps<PageProps>, 'pageProps'> & {
    pageProps: PageProps
  } & {
    Component: NextPageWithLayout
  } & PageProps

export default function CustomApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => <Layout>{page}</Layout>)
  const [clientSideAppStarted, cookiesSet, user] = useUnit([appStarted, clientCookiesSet, $userProfile])
  const [planModalWindow, setPlanModalWindow] = useState(false)

  useEffect(() => {
    if (!user) {
      cookiesSet((getCookie('access') as string) ?? '')
      clientSideAppStarted()
    }
  }, [clientSideAppStarted, cookiesSet, user])

  //@ts-ignore
  const ComponentWithLayout = getLayout(<Component {...pageProps} />)

  return (
    <TooltipProvider delayDuration={0}>
      <EffectorNext values={pageProps.values}>
        <IntlProvider messages={pageProps.messages} locale={pageProps.locale} timeZone={'UTC'}>
          <Seo />
          <PlanModalWindowContext.Provider value={{ planModalWindow, setPlanModalWindow }}>
            <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? ''}>
              {ComponentWithLayout}
            </GoogleOAuthProvider>
          </PlanModalWindowContext.Provider>
        </IntlProvider>
      </EffectorNext>
    </TooltipProvider>
  )
}

CustomApp.getInitialProps = async (context: AppContext) => {
  const appProps = await App.getInitialProps(context) // Retrieves page's `getInitialProps`

  const { ctx } = context

  const isLogout = ctx?.req?.url?.includes('logout') || ctx?.req?.headers?.referer?.includes('logout')
  const access = getCookie('access', {
    ...ctx,
  })

  const refresh = getCookie('refresh', {
    ...ctx,
  })

  const savedLocale = getCookie(CookieFields['locale'], {
    ...ctx,
  })

  const scope = fork({
    values: [
      [$userToken, access ?? ''],
      [$refreshToken, refresh ?? ''],
    ],
  })

  await allSettled(clientCookiesSet, {
    scope,
    params: access,
  })

  if (access && refresh && !isLogout) {
    await allSettled(appStarted, {
      scope,
    })
  } else if (isLogout) {
    deleteCookie('access', {
      ...ctx,
    })
    deleteCookie('refresh', {
      ...ctx,
    })
    await allSettled(userLoggedOut, { scope })
  }

  const values = serialize(scope)
  const targetLocale = savedLocale || ctx.locale || ctx.defaultLocale || 'ru'
  const messages = (await import(`../../messages/${targetLocale}/common.json`)).default

  return {
    pageProps: {
      ...appProps,
      values,
      messages,
      locale: targetLocale,
    },
  }
}
