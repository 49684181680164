import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'

import { $userProfile } from '@/entities/user'
import { SafeView } from '@/features'
import { Connections, Contacts, CourtCases, Risks } from 'src/screens/company/ui'

import { $individual, $individualRelations, $individualRisks, $individualsMeta, $isFull } from './model'
import { IndividualBrief, IndividualMainInfo } from './ui'

export function IndividualsScreen() {
  const user = useUnit($userProfile)
  const t = useTranslations()

  console.log($individualRelations)

  return (
    <SafeView
      for={user && user?.plan_id >= 3}
      otherwise={
        <main className="container flex h-full w-full flex-1 items-center justify-center">
          <span>{t(`only_for_subscribers_premium`)}</span>
        </main>
      }
    >
      <main className="container mx-auto flex-auto">
        <IndividualBrief />
        <IndividualMainInfo />

        {/* @ts-ignore*/}
        <Contacts for={$individual} person isFull={$isFull} meta={$individualsMeta} />
        {/*@ts-ignore*/}
        <Connections for={$individual} relations={$individualRelations} isFull={$isFull} person />

        {/*@ts-ignore*/}
        <CourtCases for={$individual} person isFull={$isFull} />

        {/*@ts-ignore*/}
        <Risks for={$individual} risks={$individualRisks} person />
      </main>
    </SafeView>
  )
}
