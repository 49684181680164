import { generateHeaders } from '@/api/model'
import axios from 'axios'
import { useTranslations } from 'next-intl'
import React, { useState } from 'react'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  getData?: () => void
}

const ModalCreateList: React.FC<ModalProps> = ({ isOpen, onClose, getData }) => {
  const [name, setName] = useState('')
  const [error, setError] = useState<string | null>(null) // Используем string или null для состояния ошибки
  const t = useTranslations()

  if (!isOpen) return null

  const setData = async (name: string) => {
    const token = localStorage.getItem('token')
    const headers = {
      ...generateHeaders(),
      Authorization: `Bearer ${token}`,
    }

    await axios.post(`${process.env.NEXT_PUBLIC_API_URL}/user/favourite/list`, { name }, { headers })
    if (getData) await getData()
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (!name.trim()) {
      setError(t('requer_field'))
      return
    }
    setData(name)
    onClose()
    setName('')
    setError(null)
  }

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      style={{ zIndex: '1000' }}
      onClick={onClose}
    >
      <div className="rounded-lg bg-white p-8" style={{ width: '350px' }} onClick={(e) => e.stopPropagation()}>
        <h1 className={'font-stem text-xl'} style={{ margin: '0 0 10px 0' }}>
          {t('adding_new_list')}
        </h1>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder={t('set_name_list')}
            value={name}
            onChange={(e) => {
              setName(e.target.value)
              if (error) setError(null)
            }}
            className={
              'border-right !h-[33px] h-12 h-full w-full w-full justify-end rounded border px-2 py-1.5 text-sm text-sm text-black outline-none transition-shadow duration-300 focus:shadow focus:outline-none md:justify-start md:focus:shadow-none'
            }
          />
          {error && <p className="mt-2 text-xs text-red-500">{error}</p>}
          <div className="mt-4 flex justify-around space-x-4">
            <button
              type="button"
              className="text-md cursor-pointer rounded bg-red-500 p-2 font-medium text-white transition hover:bg-red-600"
              onClick={onClose}
            >
              {t('cancel')}
            </button>
            <button
              type="submit"
              className="text-md rounded bg-blue-500 p-3 font-medium text-white transition hover:bg-blue-600"
            >
              {t('save')}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ModalCreateList
