import * as Popover from '@radix-ui/react-popover'
import { PopoverContentProps } from '@radix-ui/react-popover'
import { useUnit } from 'effector-react'
import { useLocale, useTranslations } from 'next-intl'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useRef, useState } from 'react'

import { ROUTES, availablePlansByTitle } from '@/constants'
import { $userProfile, userLoggedOut } from '@/entities/user'
import { SafeView } from '@/features'
import Icon from '@shared/ui/icon'
import { Avatar, AvatarFallback } from 'src/shared/ui/avatar'

const links = ['personal-area', 'order-history', 'payment-history', 'unloading-history']

export const UserDropdown = () => {
  const [user, logout] = useUnit([$userProfile, userLoggedOut])
  const [isOpen, updateIsOpen] = useState(false)
  const router = useRouter()
  const t = useTranslations()
  const locale = useLocale()
  const trigger = useRef<HTMLButtonElement>(null)
  const toggleOpen = () => updateIsOpen((isOpen) => !isOpen)
  const onInteractOutside: PopoverContentProps['onInteractOutside'] = (e) => {
    if (!trigger) return

    const isClickOnTrigger = e.target === trigger.current
    if (isClickOnTrigger) return

    updateIsOpen(false)
  }

  const getWidthForRange = () => {
    if (!user) return 0

    let width = 0
    const reports = user.reports || 0

    if (reports > 1000) {
      width = 0
    } else if (availablePlansByTitle.Premium.includes(user.plan_id)) {
      width = (reports / 1000) * 100
    } else if (availablePlansByTitle.Basic.includes(user.plan_id)) {
      width = (reports / 20) * 100
    }
    return 100 - width
  }

  const handleLogout = () => {
    logout()
    router.push('/auth/logout')
  }

  return (
    <SafeView for={user} withProps otherwise={null}>
      {(user) => (
        <Popover.Root open={isOpen}>
          <Popover.Trigger onClick={toggleOpen} ref={trigger}>
            <li className="flex items-center space-x-2 py-2 md:py-0">
              <Avatar className="w-7 h-7">
                <AvatarFallback className="bg-gray-200 border-[3px] border-transparent hover:border-gray-300 transition-colors text-gray-700 font-medium text-xs">
                  {user &&
                    user?.name
                      .split(' ')
                      .map((u) => u.at(0)?.toUpperCase())
                      .join('')}
                </AvatarFallback>
              </Avatar>
              <div className="block w-full md:hidden">
                <Icon className="ml-auto" name="arrow-down" size={24} color="blue" />
              </div>
            </li>
          </Popover.Trigger>
          <Popover.Content onInteractOutside={onInteractOutside} side="bottom">
            <ul className="z-50 flex w-[100vw] transform flex-col rounded-md bg-white py-2 text-sm text-gray-800 shadow-lg duration-300 md:w-[13rem]">
              {user && (
                <>
                  <li className="px-4">{user.name}</li>
                  <li className="px-4 text-xs text-gray-400">{user.email}</li>
                </>
              )}
              {user?.plan_id !== 0 ? (
                <>
                  <li className="hover:bg-blue-50">
                    <div className="my-2 w-full px-4">
                      <div className="h-2 w-full rounded bg-gray-300">
                        <div
                          className={`h-2 rounded bg-gradient-to-r from-blue-500  transition-all duration-300 ${
                            user?.reports > 1000 ? 'to-blue-300' : 'to-blue-500'
                          } `}
                          style={{ width: getWidthForRange() + '%' }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  <li className="px-4 py-1 hover:bg-blue-50">
                    <div className="font-medium text-black">
                      <SafeView
                        for={user.reports > 1000}
                        otherwise={
                          <>
                            <SafeView for={availablePlansByTitle.Premium.includes(user.plan_id)} otherwise={null}>
                              <span>
                                {1000 - (user.reports || 0)}{' '}
                                {t(`from_for_range`, {
                                  count: 1000,
                                })}
                              </span>
                            </SafeView>

                            <SafeView for={availablePlansByTitle.Basic.includes(user.plan_id)} otherwise={null}>
                              <span>
                                {20 - (user?.reports || 0)}{' '}
                                {t(`from_for_range`, {
                                  count: 20,
                                })}
                              </span>
                            </SafeView>
                          </>
                        }
                      >
                        <span>
                          {user.reports} из {user.plan_reports} использовано
                        </span>
                      </SafeView>
                    </div>
                  </li>
                  {availablePlansByTitle.Basic.includes(user.plan_id) ? (
                    <li className="hover:opacity-7 py-15 px-4">
                      <div className="text-gray-600">
                        <span>{t(`need_more_order`)}</span>
                        <Link locale={locale} href={ROUTES.PRICING} rel="nofollow">
                          <span
                            style={{
                              background: '#e8f1fa',
                              fontWeight: 500,
                            }}
                            className="text-blue mt-1 flex cursor-pointer items-center justify-between rounded-md px-2 py-1 text-sm text-primary hover:text-flamingo"
                          >
                            {t(`start`)} Premium
                          </span>
                        </Link>
                      </div>
                    </li>
                  ) : null}
                </>
              ) : (
                <li className="px-4 py-1">
                  <div className="text-black">
                    <span>{t(`need_more_order`)}</span>
                    <Link locale={locale} href={ROUTES.PRICING} rel="nofollow">
                      <span
                        style={{
                          background: '#e8f1fa',
                          fontWeight: 500,
                        }}
                        className="text-blue mt-1 flex cursor-pointer items-center justify-between rounded-md px-2 py-1 text-sm text-primary hover:text-flamingo"
                      >
                        {t(`start`)} Premium
                      </span>
                    </Link>
                  </div>
                </li>
              )}

              <div className="my-2 border-t border-gray-100" />
              {links.map((link) => (
                <li className="px-4 py-1 hover:bg-blue-50" key={link}>
                  <Link locale={locale} href={`/me/${link}`}>
                    {t(link)}
                  </Link>
                </li>
              ))}

              <li className="px-4 py-1 hover:bg-blue-50">
                <button type="button" className="outline-0" onClick={handleLogout}>
                  {t('logout')}
                </button>
              </li>
            </ul>
          </Popover.Content>
        </Popover.Root>
      )}
    </SafeView>
  )
}
