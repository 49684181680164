import { allSettled, fork, serialize } from 'effector'
import type { GetServerSidePropsContext } from 'next'

import { $refreshToken, $userToken, appStarted } from '@/shared-events'
import { CookieFields } from '@/T'

export const loadUserAndTranslates = async (ctx: GetServerSidePropsContext, withSerialize = true) => {
  const { access, refresh } = ctx.req.cookies

  const scope = fork({
    values: [
      [$userToken, access || ''],
      [$refreshToken, refresh || ''],
    ],
  })

  await allSettled(appStarted, {
    scope,
  })

  const targetLocale = ctx.req.cookies[CookieFields['locale']] || ctx.locale || ctx.defaultLocale || 'ru'
  const messages = (await import(`../../messages/${targetLocale}/common.json`)).default

  if (withSerialize) {
    const values = serialize(scope)
    return {
      values,
      messages,
      scope,
    }
  }

  return { messages, scope }
}
